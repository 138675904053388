const dayjs = useDayjs();

function isRelative(date: string) {
  const now = dayjs();
  const dateObj = dayjs(date);
  return now.diff(dateObj, "days") <= 1;
}

type Props = {
  date?: string | null;
  time?: boolean;
  relative?: boolean;
  relativeDays?: boolean;
  reduced?: boolean;
};

export default function formatDate({ date, time, relative, relativeDays, reduced }: Props) {
  if (!date) return "";
  const locale = dayjs.locale();
  if (time) {
    return dayjs(date).format(
      locale === "de" ? `HH:mm ${!reduced ? "[Uhr]" : ""}` : `h:mm ${!reduced ? "A" : ""}`
    );
  }
  if (relativeDays) {
    const isToday =
      dayjs(date).get("day") === dayjs().get("day") &&
      dayjs(date).get("month") === dayjs().get("month") &&
      dayjs(date).get("year") === dayjs().get("year");
    if (isToday) return locale === "de" ? "Heute" : "Today";
    const isYesterday =
      dayjs(date).get("day") === dayjs().subtract(1, "day").get("day") &&
      dayjs(date).get("month") === dayjs().subtract(1, "day").get("month") &&
      dayjs(date).get("year") === dayjs().subtract(1, "day").get("year");
    if (isYesterday) return locale === "de" ? "Gestern" : "Yesterday";
  }
  if (relative && isRelative(date)) return dayjs(date).locale(locale).fromNow();
  const year = dayjs(date).get("year") === dayjs().get("year") ? "" : " YYYY";
  const timeDe = reduced ? "" : ", HH:mm [Uhr]";
  const timeEn = reduced ? "" : ", h:mm A";
  return dayjs(date).format(locale === "de" ? `D. MMMM${year}${timeDe}` : `MMMM D${year}${timeEn}`);
}
